import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"





const Header = () => (
  <header
    style={{
      position: "sticky",
      top: 0,     
      backgroundColor:"#cedff0",
      margin: 0,
      padding: 0,
      display: `flex`,
      alignItems: "left",
      justifyContent: "space-between",
      zIndex:100,
    }}
  >
    <nav>
    <ul>
      <ag ><Link to="/">home</Link></ag> &middot;
      <ag><Link to="/page-2">projects</Link></ag> &middot;
      <ag><Link to="/Contact">contact</Link></ag>
    
    </ul>
    </nav>
  </header>
)

export default Header
